import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Plus } from 'lucide-react';
import camelcaseKeys from 'camelcase-keys';
import { Button, Modal } from 'antd';

import DNDColumn, { KanbanColumn } from './drag_and_drop/column';
import { fetchTags } from 'api/CommandApi';
import { getCurrentProjectId } from 'models/Organization';
import { getCurrentProjectTags, setCurrentProjectTags } from 'models/Project';
import BulkActionsBar, { BulkRequest } from './bulk_actions_bar';
import NewColumnHeader from './column_header/new_column_header/NewColumnHeader';
import {
  getMultiSelectList,
  wasMultiSelectKeyUsed,
  wasToggleInSelectionGroupKeyUsed
} from './multiSelect/Util';
import styles from './index.module.css';
import { Task } from 'appJS/models/Task';
import { ProjectProvider } from '../../../../clients/providers/Project';
import { processArchiveBulkRequest } from 'jsViews/kanban/createBulkRequest';
import { buildSceneState, SceneColumn } from './scene';
import usePusherSubscribe from 'appJS/hooks/usePusherSubscribe';
import { Event, Subscription } from 'appJS/models/Pusher';
import { useCurrentUserState } from '../../../../clients/providers/CurrentUser';

export const MAX_NUMBER_OF_TASKS = 50;

interface CardsProps {
  columns: KanbanColumn[];
  setActiveTask: (activeTask: number) => void;
  activeTask: number;
  bulkActions: BulkRequest[];
  moveTaskToFeedback: () => void;
  onDeleteColumn: (columnId: string) => void;
  moveTaskToArchive: () => void;
  getAssignees: () => void;
  updateCardPosition: (
    cardId: string,
    column: SceneColumn,
    index: number,
    tasks: Task[],
    justUpdateState: boolean
  ) => void;
  updateColumnPosition: (
    columnId: number,
    index: number,
    columns: SceneColumn[]
  ) => void;
  updateColumnName: () => void;
  onAddColumn: (newColumn: KanbanColumn) => void;
  closeTask: () => void;
  bulkArchiveTasks: () => void;
  tags: Record<number, any>;
  goToArchivePage: (...args: unknown[]) => unknown;
  getCurrentRole: (...args: unknown[]) => unknown;
  experiments: string[];
  editTaskStatus: boolean;
  editTaskAssignees: boolean;
  editTaskTags: boolean;
  editTaskSeverity: boolean;
  editTaskDueDate: boolean;
  viewGuestKanban: boolean;
  pusherChannelName: string;
  projectId: number;
  pusherApiKey: string;
}

const getContainer = () =>
  document.getElementById('kanbanBoard') as HTMLDivElement;

const Cards: React.FC<CardsProps> = ({
  moveTaskToFeedback,
  moveTaskToArchive,
  activeTask,
  closeTask,
  bulkArchiveTasks,
  columns,
  getAssignees,
  setActiveTask,
  updateCardPosition,
  updateColumnPosition,
  updateColumnName,
  onDeleteColumn,
  onAddColumn,
  goToArchivePage,
  getCurrentRole,
  bulkActions,
  editTaskAssignees,
  editTaskTags,
  editTaskSeverity,
  editTaskDueDate,
  viewGuestKanban,
  pusherChannelName,
  projectId,
  pusherApiKey,
  ...props
}) => {
  const [newColumnPosition, setNewColumnPosition] = useState<null | number>(
    null
  );
  const [draggingCardFromColumnId, setDraggingCardFromColumnId] = useState<
    null | string
  >(null);
  const [selectedTaskList, setSelectedTaskList] = useState<string[]>([]);
  const [selectedColumnIndexId, setSelectedColumnIndexId] = useState<
    null | number
  >(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [toDelete, setToDelete] = useState<string | null>(null);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [tasksToArchive, setTasksToArchive] = useState();
  const [archiveColumn, setArchiveColumn] = useState();
  const [editTaskStatus, setEditTaskStatus] = useState<boolean>(
    props.editTaskStatus
  );

  const tags = props.tags || getCurrentProjectTags();
  const scene = buildSceneState(columns);

  const { currentUser } = useCurrentUserState();

  const handletaskEditStatusUpdate = (
    user: { role: string },
    pusherData: any
  ) => {
    if (user?.role === 'guest') {
      setEditTaskStatus(pusherData.guest_edit_task_status);
    }
  };

  usePusherSubscribe({
    pusher: {
      config: {
        pusherApiKey,
        pusherChannelAuthEndpoint: '/pusher/auth'
      },
      projects: [{ id: String(projectId), pusherChannelName }],
      events: [
        {
          name: Event.GUEST_EDIT_STATUS_UPDATED,
          onUpdate: handletaskEditStatusUpdate.bind(null, currentUser)
        }
      ],
      subscription: Subscription.KANBAN
    },
    loading: !currentUser
  });

  const unSelectAll = () => {
    if (selectedTaskList.length > 0 || selectedColumnIndexId != null) {
      setSelectedTaskList([]);
      setSelectedColumnIndexId(null);
    }
  };

  const onWindowClick = event => {
    if (event.defaultPrevented) {
      return;
    }
    unSelectAll();
  };

  const onWindowKeyDown = event => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.key === 'Escape') {
      unSelectAll();
    }

    if (selectedTaskList.length < 1) {
      if (
        wasMultiSelectKeyUsed(event) ||
        wasToggleInSelectionGroupKeyUsed(event)
      ) {
        if (activeTask) {
          setSelectedTaskList([activeTask.toString()]);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', onWindowKeyDown);

    return () => {
      window.removeEventListener('click', onWindowClick);
      window.removeEventListener('keydown', onWindowKeyDown);
    };
  }, [columns]);

  const insertFieldForNewColumn = (insertAtIndex: number) => {
    setNewColumnPosition(insertAtIndex);
  };

  const resetNewColumn = () => {
    setNewColumnPosition(null);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleArchiveModal = () => {
    setShowArchiveModal(!showArchiveModal);
  };

  const getColumnToDelete = columnId => {
    setToDelete(columnId);
  };

  const confirmDeleteColumn = () => {
    if (!toDelete) {
      throw new Error('No column to delete');
    }

    toggleDeleteModal();
    onDeleteColumn(toDelete);
  };

  const getArchive = (tasksToArchive, columnId) => {
    setTasksToArchive(tasksToArchive);
    setArchiveColumn(columnId);
  };

  const confirmArchiveColumn = () => {
    // @ts-ignore
    processArchiveBulkRequest({
      columnId: archiveColumn,
      taskIds: tasksToArchive
    });

    toggleArchiveModal();
  };

  const handleAddColumn = newColumn => {
    resetNewColumn();
    onAddColumn(newColumn);
  };

  const _onColumnDrop = result => {
    const { destination, source } = result;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    const columns = scene.children;
    // Remove dragged column
    const removedColumn = columns.splice(source.index, 1);

    // Add drag column back in order
    columns.splice(destination.index, 0, removedColumn[0]);

    // Update backbone
    updateColumnPosition(removedColumn[0].id, destination.index, columns);
  };

  const getTasksInColumn = column => {
    const foundColumn = scene.children.find(col => {
      return column.id === col.id && column.status_id === col.status_id;
    });

    if (foundColumn) {
      return foundColumn.children;
    }
    return [];
  };

  const _onCardDrop = result => {
    const { destination, source } = result;
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    const sourceColumn = JSON.parse(source.droppableId);
    const destinationColumn = JSON.parse(destination.droppableId);
    const sourceTasks = getTasksInColumn(sourceColumn);
    const tasks = getTasksInColumn(destinationColumn);

    let removedTask;

    // Remove dragged card
    if (destination.droppableId === source.droppableId) {
      removedTask = tasks.splice(source.index, 1);
    } else {
      removedTask = sourceTasks.splice(source.index, 1);
    }

    // Add drag card back in order
    tasks.splice(destination.index, 0, removedTask[0]);

    // Update backbone
    updateCardPosition(
      removedTask[0].id,
      destinationColumn,
      destination.index,
      tasks,
      false
    );
  };

  const handleDragStart = result => {
    if (selectedTaskList) unSelectAll();

    if (result.type === 'card') {
      const droppableId = result.source.droppableId;
      if (draggingCardFromColumnId !== droppableId) {
        setDraggingCardFromColumnId(droppableId);
      }
    }
  };

  const onDragEnd = result => {
    setDraggingCardFromColumnId(null);

    // dropped nowhere
    if (!result.destination) {
      return;
    }

    if (result.type === 'column') {
      _onColumnDrop(result);
    }

    if (result.type === 'card') {
      _onCardDrop(result);
    }
  };

  const getColumnAction = columnIndex => {
    if (columnIndex === 0) {
      return 'toFeedback';
    } else if (columnIndex + 1 === scene.children.length) {
      return 'toArchive';
    }
    return null;
  };

  const multiSelect = (taskId, action, columnId) => {
    const selectedList = selectedTaskList;
    const columnTaskIdList = scene.children[columnId].children;

    if (selectedList.length > MAX_NUMBER_OF_TASKS) {
      setSelectedTaskList(selectedTaskList.slice(0, MAX_NUMBER_OF_TASKS));
    }

    if (selectedColumnIndexId === null) {
      setSelectedColumnIndexId(columnId);
    }
    if (selectedColumnIndexId !== null && selectedColumnIndexId !== columnId) {
      setSelectedColumnIndexId(columnId);
      setSelectedTaskList([taskId]);
    } else if (
      selectedList.length !== 0 &&
      selectedList.length < MAX_NUMBER_OF_TASKS
    ) {
      const selectedIdList = getMultiSelectList(
        action,
        taskId,
        selectedList,
        columnTaskIdList
      );
      setSelectedTaskList([...selectedIdList]);
    } else {
      if (selectedList.length < MAX_NUMBER_OF_TASKS) {
        setSelectedTaskList([...selectedTaskList, taskId]);
      }
    }

    setSelectedTaskList(previousValue =>
      previousValue
        .slice()
        .filter(id => columnTaskIdList.find(task => task.id === id))
    );
  };

  // limiting the number of multiple selections cause we dont know what the effect will be when we bulk update a tonne of tasks
  useEffect(() => {
    if (selectedTaskList.length > MAX_NUMBER_OF_TASKS) {
      setSelectedTaskList([...selectedTaskList.slice(0, MAX_NUMBER_OF_TASKS)]);
    }
  }, [selectedTaskList]);

  useEffect(() => {
    if (!tags) {
      fetchTags({ projectId: getCurrentProjectId() }).then(payload => {
        if (payload.data) {
          setCurrentProjectTags(payload.data);
        }
      });
    }
  }, [tags]);

  const totalColumns = scene.children.length;

  const canManageColumn = window?.bugherd?.application?.get('access_to')[
    'manage_column'
  ];

  return (
    <ProjectProvider id={`${getCurrentProjectId()}`} apiDomain="">
      <div key="headerMask" className={styles.headerMask} />
      <div key="board" className={styles.board}>
        <BulkActionsBar
          selectedTasks={selectedTaskList}
          getContainer={getContainer}
          onClose={unSelectAll}
          bulkActions={bulkActions}
          permissions={{
            editTaskStatus,
            editTaskAssignees,
            editTaskTags,
            editTaskSeverity,
            editTaskDueDate
          }}
        />
        <Modal
          className={styles.deleteModal}
          title="Delete column"
          closable
          open={showDeleteModal}
          onCancel={toggleDeleteModal}
          onOk={confirmDeleteColumn}
          okText="Yes"
          cancelText="No, thanks"
          getContainer={getContainer}
        >
          <p className={styles.deleteMessage}>
            Are you sure you want to delete this column?
          </p>
        </Modal>
        <Modal
          title="Archive tasks"
          className={styles.deleteModal}
          closable
          open={showArchiveModal}
          onCancel={toggleArchiveModal}
          onOk={confirmArchiveColumn}
        >
          <p className={styles.deleteMessage}>
            Are you sure you want to archive all tasks in this column?
          </p>
        </Modal>
        <div className={styles.boardContent}>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={handleDragStart}>
            <Droppable droppableId="board" type="column" direction="horizontal">
              {provided => (
                <div
                  className={styles.draggableColumnContainer}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {scene.children.flatMap((column, index) => {
                    const columnsWrapper: any[] = [];
                    columnsWrapper.push(
                      <DNDColumn
                        {...props}
                        key={`${column.id}-${column.status_id}`}
                        isActive={
                          draggingCardFromColumnId ===
                          JSON.stringify({
                            id: column.id,
                            status_id: column.status_id
                          })
                        }
                        id={column.id}
                        cards={column.children}
                        index={index}
                        column={column}
                        canDelete={scene.children.length < 3}
                        insertFieldForNewColumn={insertFieldForNewColumn}
                        toggleDeleteModal={toggleDeleteModal}
                        toggleArchiveModal={toggleArchiveModal}
                        getColumnToDelete={getColumnToDelete}
                        getArchive={getArchive}
                        activeTaskId={activeTask}
                        moveTaskToFeedback={moveTaskToFeedback}
                        moveTaskToArchive={moveTaskToArchive}
                        getAssignees={getAssignees}
                        columnAction={getColumnAction(index)}
                        selectedTaskList={selectedTaskList}
                        multiSelect={multiSelect}
                        unSelectAll={unSelectAll}
                        closeDetails={closeTask}
                        bulkArchiveTasks={bulkArchiveTasks}
                        unfilteredTaskCount={column.unfilteredTaskCount}
                        setActiveTask={setActiveTask}
                        updateColumnName={updateColumnName}
                        goToArchivePage={goToArchivePage}
                        getCurrentRole={getCurrentRole}
                        assets={camelcaseKeys(props.assets, { deep: true })}
                        viewGuestKanban={viewGuestKanban}
                        editTaskStatus={editTaskStatus}
                      />
                    );

                    if (newColumnPosition === index + 1) {
                      columnsWrapper.push(
                        <div className={styles.column}>
                          <NewColumnHeader
                            onAddColumn={handleAddColumn}
                            insertAtIndex={index + 1}
                            resetNewColumn={resetNewColumn}
                          />
                        </div>
                      );
                    }

                    return columnsWrapper;
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          {canManageColumn && newColumnPosition === null && (
            <div className={styles.addColumnContainer}>
              <Button
                className={styles.addColumn}
                data-cy="addColumn"
                type="default"
                onClick={() => insertFieldForNewColumn(totalColumns)}
                title="Add new column!"
                icon={<Plus className={styles.addIcon} />}
              />
            </div>
          )}
        </div>
      </div>
    </ProjectProvider>
  );
};

export default Cards;
