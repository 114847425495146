import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import cx from 'classnames';
import { ProjectNavProps, View, NavItem as NavItemType } from './types';
import navItems from './items';
import { NavItem } from './nav_item';
import { Button, Divider } from 'antd';
import useMediaQuery from '../../../../../clients/sidebar/views/CreateTask/create_task/useMediaQuery';
import { useDesignAssetsState } from '../../../../clients/providers/DesignAssets';
import { BETA_USER_SURVEY_FEEDBACK_ID } from '../../../../clients/providers/DesignAssets';
import { ActiveKey } from '../../new_project_settings/types';
import { HandCoins } from 'lucide-react';

export const GUEST_NAV_ITEMS: (View | null)[] = [
  View.Home,
  View.Triage,
  View.Kanban,
  View.Help,
  null // Corresponds to the back button TODO fix this
];

export const MEMBER_NAV_ITEMS: (View | null)[] = [
  View.GettingStarted,
  View.Assets,
  View.Triage,
  View.Kanban,
  View.Archive,
  View.Settings,
  View.Integrations,
  View.Members,
  View.Guests,
  View.Tags,
  null
];

export const ProjectNav: FC<ProjectNavProps> = ({
  triageCount,
  kanbanCount,
  gettingStartedCount,
  tags,
  users,
  collapsed,
  toggleCollapsed,
  setView,
  view,
  activeKey,
  setActiveKey,
  showGettingStartedChecklist,
  hasNewOnboarding,
  canAccess,
  projectId,
  organizationId,
  showCello
}) => {
  const collapseNavSize = useMediaQuery('(max-width: 800px)');
  const { setSurveyId, setShowAssets } = useDesignAssetsState();
  const [celloExists, setCelloExists] = useState(false);

  useEffect(() => {
    if (!collapsed && collapseNavSize) {
      toggleCollapsed();
    }
  }, [collapseNavSize]);

  useEffect(() => {
    const maxTimeSpentChecking = 10000;
    let timeSpentChecking = 0;
    const intervalTime = 1000;

    const interval = setInterval(() => {
      if ('cello' in window) {
        setCelloExists(true);
        clearInterval(interval);
      } else if (timeSpentChecking >= maxTimeSpentChecking) {
        clearInterval(interval);
      }

      timeSpentChecking += intervalTime;
    }, intervalTime);

    return () => clearInterval(interval);
  }, []);

  const referralButton = () => {
    if (!celloExists || !showCello) {
      return null;
    }

    return (
      <div className={styles.referralContainer}>
        <Button
          id="cello-referral"
          block={!collapsed}
          icon={<HandCoins size={16} />}
          shape={collapsed ? 'circle' : 'default'}
        >
          {!collapsed && 'Refer & earn'}
        </Button>
      </div>
    );
  };

  const handleItemClick = (event, key: View | null) => {
    switch (key) {
      case View.Members:
      case View.Guests:
      case View.Collaborators:
        window.share_project_props.setRole(key.slice(0, -1));
        window.share_project_props.setShow(true);
        break;
      case View.Settings:
        setActiveKey(ActiveKey.General);
        setView(key);
        break;
      case View.Assets:
        event.preventDefault();
        //@ts-expect-error
        window.bugherd.applicationView.showAssets();
        setSurveyId(BETA_USER_SURVEY_FEEDBACK_ID);
        setShowAssets(true);
        setView(key);
        break;
      case View.Help:
        event.preventDefault();
        //@ts-expect-error
        window._elev?.openArticle('85001');
        break;
      case View.Home:
        event.preventDefault();
        //@ts-expect-error
        window.bugherd.applicationView.showAssets();
        setShowAssets(true);
        setView(key);
        break;
      case null:
        toggleCollapsed();
        break;
      default:
        setView(key);
        break;
    }
  };

  const items = navItems({
    triageCount: canAccess.viewKanbanProject ? triageCount : 0,
    kanbanCount: canAccess.viewKanbanProject ? kanbanCount : 0,
    gettingStartedCount,
    tags,
    users,
    setView,
    hasNewOnboarding
  })
    .filter(({ key }) => {
      const hideGettingStarted = !showGettingStartedChecklist;
      if (key === View.GettingStarted && hideGettingStarted) {
        return false;
      }
      return true;
    })
    .filter(({ key }) => {
      if (canAccess.viewKanbanProject) {
        return MEMBER_NAV_ITEMS.includes(key);
      } else {
        return GUEST_NAV_ITEMS.filter(
          navItem =>
            canAccess.viewGuestKanban ||
            navItem === View.Home ||
            navItem === View.Help
        ).includes(key);
      }
    });

  const isActive = (key: View | null) => {
    const isActiveKey = key === activeKey;
    const isActiveView = key === view;

    const isInSettings = view === View.Tags || view === View.Integrations;
    return isInSettings ? isActiveView && isActiveKey : isActiveView;
  };

  const Item = ({ item }: { item: NavItemType }) => (
    <NavItem
      collapsed={collapsed}
      active={isActive(item.key)}
      onClick={event => handleItemClick(event, item.key)}
      item={item}
      view={view}
    />
  );

  return (
    <>
      <div className={cx(styles.container, { [styles.collapsed]: collapsed })}>
        {/* main section */}
        <div
          className={cx(styles.mainSection, {
            [styles.mainSectionExpanded]: !collapsed,
            [styles.gettingStartedView]: view === View.GettingStarted
          })}
        >
          {items
            .filter(item => item.section === 'design')
            .map((navItem, index) => {
              const { withDivider, key } = navItem;
              return (
                <div className={styles.itemOuter} key={key}>
                  <Item item={navItem} />
                  {withDivider && (
                    <Divider
                      key={`divider-${index}`}
                      className={styles.divider}
                    />
                  )}
                </div>
              );
            })}
          {items
            .filter(item => item.section === 'main')
            .map((navItem, index) => {
              const { withDivider, key } = navItem;
              return (
                <div className={styles.itemOuter} key={key}>
                  <Item item={navItem} />
                  {withDivider && (
                    <Divider
                      key={`divider-${index}`}
                      className={styles.divider}
                    />
                  )}
                </div>
              );
            })}
        </div>
        {/* body section */}
        <div>
          {items
            .filter(item => item.section === 'body')
            .map((navItem, index) => {
              const { withDivider, component, key } = navItem;
              return collapsed && key === 'guests' ? null : (
                <div className={styles.itemOuter} key={key}>
                  {(!collapsed && component) || <Item item={navItem} />}
                  {withDivider && (
                    <Divider
                      key={`divider-${index}`}
                      className={styles.divider}
                    />
                  )}
                </div>
              );
            })}
        </div>
        {/* footer section */}
        <div
          className={cx(styles.footerSection, {
            [styles.footerSectionExpanded]: !collapsed
          })}
        >
          {items
            .filter(item => item.section === 'footer')
            .map((navItem, index) => {
              const { key } = navItem;
              navItem.label = collapsed ? 'Show menu' : 'Hide menu';
              return (
                <div className={styles.itemOuter} key={key}>
                  <Divider
                    key={`divider-${index}`}
                    className={styles.divider}
                  />
                  <Item item={navItem} />
                </div>
              );
            })}
        </div>
      </div>
      {referralButton()}
    </>
  );
};
