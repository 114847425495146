import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.css';
import FilterAndSort from 'appJS/views/FilterAndSort';
import { View } from '../project_nav/types';
import cx from 'classnames';
import { OpenWebsite } from './OpenWebsite';
import { OpenWebsiteWithProxy } from './OpenWebsiteWithProxy';
import { AddTaskOrAssetButton } from './AddTaskOrAssetButton';
import AssetsHeader from '../../../../clients/design_assets/AssetsHeader';
import AssetsGuestHeader from '../../../../clients/design_assets/AssetsGuestHeader';
import InstallExtension from '../../../../clients/design_assets/Sites/InstallExtension';
import { checkForExtension } from 'utils/checkForBugherdExtension';
import { useOrganizationState } from '../../../../clients/providers/Organization';

type Props = {
  projectSites: {
    url: string;
    id: number;
  }[];
  devurl: string;
  view: View | null;
  isMobileView: boolean;
  isReviewApp: boolean;
  isAdminUser: boolean;
  apiKey: string;
  project: any;
  hasNewOnboardingExperiment: boolean;
};

export const ProjectHeader: FC<Props> = ({
  projectSites,
  devurl,
  view,
  isMobileView,
  isReviewApp,
  isAdminUser,
  apiKey,
  project,
  hasNewOnboardingExperiment
}) => {
  const container = document.getElementById('project_header_container');
  const hasFilter =
    view === View.Kanban || view === View.Archive || view === View.Triage;
  const [hasExtensionInstalled, setExtensionInstalled] = useState<boolean>(
    false
  );
  const { javascriptInstalled } = project || {};

  const { hasOrganizationExperiment } = useOrganizationState();
  const hasChecklistOnboardingExperiment = hasOrganizationExperiment(
    'checklist_onboarding_variant'
  );
  const hasAddWebsiteOnboardingExperiment = hasOrganizationExperiment(
    'add_website_onboarding_variant'
  );
  const hasNewOnboarding =
    hasChecklistOnboardingExperiment || hasAddWebsiteOnboardingExperiment;
  const hasAddedProjectSites = projectSites.length > 0;
  const hideActionsContainer = view === View.Assets || view === View.Home;
  let showExtensionBanner =
    hasAddedProjectSites && !hasExtensionInstalled && !javascriptInstalled;

  if (hasNewOnboarding) {
    showExtensionBanner = false;
  }

  useEffect(() => {
    (async () => {
      const exists = await checkForExtension();

      setExtensionInstalled(exists);
    })();
  }, []);

  if (view === View.GettingStarted) {
    return <></>;
  }

  return (
    <div
      className={cx(styles.container, {
        [styles.hasFilter]: hasFilter,
        [styles.isMobileView]: isMobileView
      })}
    >
      {view === View.Assets && <AssetsHeader project={project} />}
      {view === View.Home && <AssetsGuestHeader project={project} />}
      <FilterAndSort container={container} isMobileView={isMobileView} />
      {!isMobileView && !hideActionsContainer && (
        <div className={styles.actions}>
          {hasNewOnboardingExperiment && showExtensionBanner && (
            <div
              className={styles.installExtensionContainer}
              id="get-extension-container"
            >
              <InstallExtension
                description="Get the BugHerd browser extension."
                showJavascriptOption={false}
                showExtensionOption
              />
            </div>
          )}
          {hasNewOnboarding ? (
            <OpenWebsiteWithProxy
              container={container}
              isMobileView={isMobileView}
              project={project}
              projectSites={projectSites}
              devurl={devurl}
              isReviewApp={isReviewApp}
              isAdminUser={isAdminUser}
              apiKey={apiKey}
            />
          ) : (
            <OpenWebsite
              container={container}
              isMobileView={isMobileView}
              projectSites={projectSites}
              devurl={devurl}
              isReviewApp={isReviewApp}
              isAdminUser={isAdminUser}
              apiKey={apiKey}
            />
          )}
          <AddTaskOrAssetButton view={view} />
        </div>
      )}
    </div>
  );
};
