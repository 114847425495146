export const en = {
  descriptionPlaceholder: 'Add description',
  assignees: 'Assignee(s)',
  status: 'Status',
  severity: 'Severity',
  tags: 'Tag(s)',
  fileSizeError: (fileName: string) =>
    `Sorry, the file (${fileName}) is too large. Maximum file size is 20mb.`,
  errorMessage: 'Something went wrong, please try again.',
  createButton: 'Create task',
  toSubmit: ' to submit',
  videoUploading: 'Uploading video. This may take a moment.',
  couldntCreate: "Something went wrong. We couldn't create your task.",
  tryAgain: 'Try again',
  taskCreated: 'Task created',
  premiumFeature: "You've discovered a premium feature!",
  extensionlessUnavailable:
    'Screenshots without a BugHerd browser extension are not available on your plan.',
  upgrade: 'Upgrade plan',
  screenshotPending: 'Why is my screenshot pending?',
  pendingMessage:
    'A screenshot will be captured after you submit this task. For instant screenshots, get the BugHerd browser extension by clicking the button below.',
  bugherdExtensions: 'Get the browser extensions',
  close: 'Close',
  moveMe: 'MOVE ME',
  disabledButtonTitle: 'Please include a relevant description',
  creatingTask: 'Creating task...',
  keyboardShortcuts: 'Keyboard shortcuts',
  control: 'control',
  command: 'command',
  return: 'return',
  esc: 'esc',
  closeModal: 'Close feedback modal',
  addTag: 'Add tag',
  addEmoji: 'Add emoji',
  submitTask: 'Submit task',
  keepTheseSettings: 'Keep these settings',
  noTagsFound: 'No tag(s) found',
  emojiShortcut: (isMac: boolean): string =>
    isMac ? 'command + control + space' : 'windows + .',
  assignShortcut: ' @',
  severityShortcut: ' !',
  statusShortcut: ' $',
  tagShortcut: ' #',
  setSeverity: 'Set the task severity',
  setStatus: 'Set the task status',
  assignUser: 'Assign a user to the task',
  addTagToTask: 'Add a tag to the task',
  accountInUse: `Looks like this email already exists. Check your email for a link to login & try again.`
};

export type Type = typeof en;
