import React, { FC, useState } from 'react';
import styles from './styles.module.css';
import { Button, Dropdown } from 'antd';
import { ExternalLink } from 'lucide-react';
import * as translations from '../../strings';
import { getLangKey } from 'appJS/models/Language';
import ProxyCompatibilityChecker from '../../../../../clients/design_assets/ProxyCompatibilityChecker';
import { useProjectSitesState } from '../../../../../clients/providers/ProjectSites';

type Props = {
  project: any;
  projectSites: {
    url: string;
    id: number;
  }[];
  container: HTMLDivElement;
  isMobileView: boolean;
  isReviewApp: boolean;
  isAdminUser: boolean;
  apiKey: string;
};

type ButtonProps = {
  project: any;
  projectSite: {
    url: string;
    id: number;
  };
  showAsLabel?: boolean;
  isMobileView: boolean;
};

const strings = translations[getLangKey()];

const OpenWebsiteButton: FC<ButtonProps> = ({
  project,
  projectSite,
  showAsLabel,
  isMobileView,
  ...restProps
}) => {
  const { proxyHost } = useProjectSitesState();

  const getTriggerElement = (loading = false) => {
    if (showAsLabel) {
      return (
        <Button type="text" loading={loading} {...restProps}>
          {projectSite?.url}
        </Button>
      );
    }

    return (
      <Button
        type="link"
        className={styles.openWebsite}
        icon={<ExternalLink className={styles.websiteIcon} />}
        title={strings.openWebsite}
        loading={loading}
        {...restProps}
      >
        {!isMobileView && strings.openWebsite}
      </Button>
    );
  };

  return (
    <ProxyCompatibilityChecker
      getTriggerElement={getTriggerElement}
      projectSiteId={projectSite?.id}
      projectSiteUrl={projectSite?.url}
      proxyUrl={`https://${project.apiKey}_${projectSite?.id}.${proxyHost}`}
      project={project}
      showProxyPreview
    />
  );
};

export const OpenWebsiteWithProxy: FC<Props> = ({
  project,
  projectSites,
  container,
  isMobileView,
  isReviewApp,
  apiKey,
  isAdminUser
}) => {
  const [sitesMenuOpen, setSitesMenuOpen] = useState<boolean>(false);

  const items: MenuProps['items'] = projectSites.map(site => ({
    key: site.id,
    label: (
      <OpenWebsiteButton
        project={project}
        projectSite={site}
        showAsLabel
        isMobileView={isMobileView}
      />
    )
  }));

  if (isReviewApp || isAdminUser) {
    items.push({
      label: (
        <Button
          type="text"
          onClick={() => window.open(`/test/widget?apikey=${apiKey}`, '_blank')}
        >
          Test Project Sidebar
        </Button>
      ),
      key: 'test'
    });
  }

  if (projectSites.length > 1 || isAdminUser) {
    return (
      <Dropdown
        className={styles.sitesDropdown}
        placement="bottomRight"
        menu={{ items, subMenuCloseDelay: 5 }}
        trigger={['hover']}
        getPopupContainer={() => container}
        open={sitesMenuOpen}
        onOpenChange={setSitesMenuOpen}
      >
        <OpenWebsiteButton
          projectSite={projectSites[0]}
          project={project}
          isMobileView={isMobileView}
        />
      </Dropdown>
    );
  } else if (projectSites.length === 1) {
    return (
      <OpenWebsiteButton
        projectSite={projectSites[0]}
        project={project}
        isMobileView={isMobileView}
      />
    );
  }
  return null;
};
