import React, { useContext, useState, useEffect, createContext } from 'react';
import { get, post, put } from '../../javascript/utils/fetch';
import usePusherSubscribe from 'appJS/hooks/usePusherSubscribe';
import { Event, Subscription } from 'appJS/models/Pusher';
import { v4 as uuid } from 'uuid';
import { UploadChangeParam } from 'antd/lib/upload';
import { message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { getFileInfo } from 'appJS/utils/fileListOperations';
import { SortBy } from '../design_assets/AssetSortMenuItems';
import { trackPage } from 'appJS/utils/analytics';
import * as translations from '../design_assets/strings';
import { getLangKey } from 'appJS/models/Language';
import {
  AssetProject,
  DesignAsset,
  DesignAssetsProviderProps,
  EventParams,
  Status,
  UploadAsset
} from '../design_assets/types';
import camelcaseKeys from 'camelcase-keys';
import { FileType } from 'appJS/utils/fileListOperations';
import uploadToS3 from 'appJS/utils/uploadToS3';
import { handleSharingClick } from '../design_assets/handleSharingClick';
import { useOrganizationState } from 'appClients/providers/Organization';
import track from '../../javascript/utils/analytics';

// @ts-ignore
const DesignAssetsStateContext = createContext();
const strings = translations[getLangKey()];

export const PLACEHOLDER_SURVEY_FEEDBACK_ID = 'Y4aOT6G3';
export const BETA_USER_SURVEY_FEEDBACK_ID = 'fvvWxOY9';
export const ASSETS_SUPPORT_ARTICLE_ID = '84911';
const MAX_FILE_SIZE = 20485760;
const FIGMA_URL = 'https://www.figma.com';

const DesignAssetsProvider = (props: DesignAssetsProviderProps) => {
  const {
    project,
    figmaClientId,
    figmaClientSecret,
    figmaCallbackURI,
    figmaRefreshToken,
    bugherdUrl,
    container,
    boardContainer,
    currentUserId,
    children,
    viewGuestKanban,
    isGuest
  } = props;
  const [figmaUserToken, setFigmaUserToken] = useState<string | null>(
    props.figmaUserToken
  );
  const [data, setData] = useState<DesignAsset[]>([]);
  const [status, setStatus] = useState<Status>(Status.LOADING);
  const [surveyId, setSurveyId] = useState<string>(
    BETA_USER_SURVEY_FEEDBACK_ID
  );
  const [showAssets, setShowAssets] = useState(
    window.location.pathname.includes('assets') ||
      window.location.pathname.includes('home')
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isFigmaModalOpen, setIsFigmaModalOpen] = useState<boolean>(false);
  const [isWebsiteModalOpen, setIsWebsiteModalOpen] = useState<boolean>(false);
  const [isGroupNameModalOpen, setIsGroupNameModalOpen] = useState<
    number | false
  >(false);
  const [groupName, setGroupName] = useState<string>('');
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [isUploadingModalOpen, setIsUploadingModalOpen] = useState<boolean>(
    false
  );
  const [needsToUpdateCache, setNeedsToUpdateCache] = useState<boolean>(true);
  const [sortBy, setSortBy] = useState<SortBy | null>(null);
  const [search, setSearch] = useState<string>('');
  const [checkingToken, setCheckingToken] = useState<boolean>(false);

  const trackAssetsButton = (type: string) => {
    track('Assets upload button clicked', {
      organization: project?.organizationId,
      project: project?.id,
      context: type
    });
  };

  const handleSearchAndSort = (): DesignAsset[] => {
    let assetsClone: DesignAsset[] = data.slice();
    if (search) {
      assetsClone = assetsClone.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (sortBy) {
      const { key } = sortBy;
      if (key === 'createdAt' || key === 'updatedAt') {
        assetsClone.sort((a: DesignAsset, b: DesignAsset) => {
          const aDate = new Date(a[key]).getTime();
          const bDate = new Date(b[key]).getTime();
          if (aDate === bDate) return a.id - b.id;
          return aDate - bDate;
        });
      } else if (key === 'name') {
        assetsClone.sort((a, b) => a.name.localeCompare(b.name));
      } else if (key === 'fileType') {
        assetsClone.sort((a, b) => Number(b.isFigma) - Number(a.isFigma));
      } else {
        // @ts-expect-error
        assetsClone.sort((a: DesignAsset, b: DesignAsset) => a[key] - b[key]);
      }
    }

    return !sortBy || sortBy.ascending ? assetsClone : assetsClone.reverse();
  };

  const { config, pusherChannelName } = project;

  const constructAssetName = (fileName: string): string =>
    fileName
      .split('.')
      .slice(0, -1)
      .join('.');

  const handleError = error => console.error(error);

  const handlePusherEvent = (
    { asset }: { asset: DesignAsset },
    handleEvent: ({
      //eslint-disable-next-line
      assetsClone,
      existingAsset,
      asset
    }: EventParams) => DesignAsset[]
  ) => {
    asset = camelcaseKeys(asset, { deep: true });
    setData(previousValue => {
      const assetsClone = previousValue.slice();
      const existingAsset = assetsClone.find(({ id }) => id === asset.id);
      return handleEvent({ assetsClone, existingAsset, asset });
    });
  };

  const onAssetCreate = ({
    assetsClone,
    existingAsset,
    asset
  }: EventParams): DesignAsset[] =>
    (!existingAsset && [...assetsClone, asset]) || assetsClone;

  const onAssetUpdate = ({
    assetsClone,
    existingAsset,
    asset
  }: EventParams): DesignAsset[] => {
    if (existingAsset) Object.assign(existingAsset, asset);
    return assetsClone;
  };

  const onAssetDelete = ({ assetsClone, asset }: EventParams): DesignAsset[] =>
    assetsClone.filter(({ id }) => id !== asset.id);

  usePusherSubscribe({
    pusher: {
      config,
      projects: [{ id: String(project.id), pusherChannelName }],
      events: [
        {
          name: Event.ASSET_CREATE,
          onUpdate: pusherData => handlePusherEvent(pusherData, onAssetCreate)
        },
        {
          name: Event.ASSET_UPDATE,
          onUpdate: pusherData => handlePusherEvent(pusherData, onAssetUpdate)
        },
        {
          name: Event.ASSET_DELETE,
          onUpdate: pusherData => handlePusherEvent(pusherData, onAssetDelete)
        }
      ],
      subscription: Subscription.USER_ASSETS
    },
    loading: false
  });

  useEffect(() => {
    (async () => {
      if (!needsToUpdateCache) return;
      try {
        const response = await get(
          `/projects/${project.id}/assets?format=json`
        );
        setData(
          response.map(asset => ({
            ...camelcaseKeys(asset, { deep: true }),
            name: constructAssetName(asset.file_name) || asset.file_name
          }))
        );
        setStatus(Status.SUCCESS);
      } catch (error) {
        console.error(error);
        setStatus(Status.ERROR);
      } finally {
        setNeedsToUpdateCache(false);
      }
    })();
  }, [project.id, needsToUpdateCache]);

  useEffect(() => {
    if (isModalOpen && surveyId === PLACEHOLDER_SURVEY_FEEDBACK_ID) {
      trackPage('Design assets survey', {
        project_id: project.id
      });
    }
  }, [isModalOpen]);

  const uploadAsset: UploadAsset = params => {
    const { onComplete } = params;
    uploadToS3({
      ...params,
      onComplete: async (
        file: FileType,
        _S3URL: string,
        key: string,
        callbackUrl: string,
        is_figma?: boolean
      ) => {
        onComplete();
        try {
          await post(callbackUrl, {
            key,
            file_name: file.name,
            is_figma: !!is_figma
          });
          setNeedsToUpdateCache(true);
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  const getFigmaData = async (extraParams: string) => {
    const figmaAPIURL = 'https://api.figma.com/v1';
    return await fetch(`${figmaAPIURL}${extraParams}`, {
      headers: {
        Authorization: `Bearer ${figmaUserToken}`
      }
    });
  };

  const handleRefreshToken = async () => {
    const refreshParams = new URLSearchParams({
      client_id: figmaClientId,
      client_secret: figmaClientSecret,
      refresh_token: figmaRefreshToken || ''
    });
    let response;

    try {
      response = await fetch(
        `${FIGMA_URL}/api/oauth/refresh?${refreshParams}`,
        { method: 'POST' }
      );

      response = await response.json();

      if (response?.message?.includes('Invalid refresh token')) {
        authFigma();
      } else {
        let userResponse = await put(`/users/${currentUserId}`, {
          figma_token: response.access_token,
          figma_expiry_token: new Date().toISOString()
        });
        const { user, error } = await userResponse.json();
        if (userResponse.status === 200) {
          setFigmaUserToken(user.figma_token);
          setCheckingToken(false);
          setIsFigmaModalOpen(true);
        } else {
          message.error(error);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const authFigma = () => {
    const callbackId = uuid();
    const oauthParms = new URLSearchParams({
      client_id: figmaClientId,
      redirect_uri: figmaCallbackURI,
      scope: 'files:read,file_comments:write',
      state: callbackId,
      response_type: 'code'
    });

    get(`/projects/${project.id}/auth_figma?callback_id=${callbackId}`)
      .then(({ status }) => {
        if (status === 200) {
          setCheckingToken(false);
          window.open(`${FIGMA_URL}/oauth?${oauthParms}`, '_self');
        }
      })
      .catch(handleError);
  };

  const handleAuthFigma = () => {
    if (figmaRefreshToken) {
      handleRefreshToken();
    } else {
      authFigma();
    }
  };

  const reAuthFigma = () => {
    authFigma();
  };

  const handleOpenFigma = async (): Promise<void> => {
    let response;
    trackAssetsButton('figma');
    try {
      setCheckingToken(true);
      response = await getFigmaData('/me');
      const { id } = await response.json();

      if (id) {
        setIsFigmaModalOpen(true);
        setCheckingToken(false);
      } else {
        handleAuthFigma();
      }
    } catch (err) {
      console.error(err);
      setCheckingToken(true);
      message.error(strings.wentWrong);
    }
  };

  const onUploadChange = (
    event: UploadChangeParam<any>,
    type: string
  ): void => {
    const file = (event?.file?.originFileObj as any) as UploadFile;

    if (!file) {
      message.error('Unrecognized file type uploaded');
      return;
    }
    if (!file.size || file.size > MAX_FILE_SIZE) {
      message.error(
        `Sorry, the file (${file.name}) is too large. Maximum file size is 20mb.`
      );
      return;
    }
    const _file = getFileInfo(file);

    const fileType =
      file.type === 'application/pdf' || file.name.endsWith('.pdf')
        ? 'pdf'
        : 'image';

    trackAssetsButton(fileType);

    const uploadParameters = {
      file: _file,
      remove: () => {},
      endpoint: `/projects/${project.id}/assets/new`,
      additionalQueryParams: { file_name: _file.name },
      feedback: message.error,
      onStart: () => {
        setIsUploadingModalOpen(true);
      },
      onProgress: () => {},
      onError: () => {},
      onComplete: () => setIsUploadingModalOpen(false)
    };
    uploadAsset(uploadParameters);
  };

  const onAddToGroup = async (groupName: string, assetId?: number) => {
    if (groupName) {
      const response = await put(
        `${bugherdUrl}/projects/${project.id}/assets/${assetId ||
          isGroupNameModalOpen}`,
        {
          group_name: groupName
        }
      );

      if (response.status === 'ok') {
        message.success(`Asset has been added to group ${groupName}`);
        setGroupName('');
      } else {
        message.error('Something went wrong, please try again.');
      }
    }
  };

  const hasManagementFeaturesExperiment = true;

  const { hasOrganizationExperiment } = useOrganizationState();

  const isNoLoginGuestExperienceEnabled = hasOrganizationExperiment(
    'no_login_guest_experience'
  );
  const onSharingClick = ({
    assetId,
    siteId,
    assetGroupName
  }: {
    assetId?: string | number;
    siteId?: string | number;
    assetGroupName?: string;
  }) => {
    handleSharingClick({
      isNoLoginGuestExperienceEnabled,
      assetId,
      siteId,
      assetGroupName,
      callback: setShowShareModal
    });
  };

  return (
    <DesignAssetsStateContext.Provider
      value={{
        project,
        currentUserId,
        figmaCallbackURI,
        figmaClientId,
        figmaUserToken,
        bugherdUrl,
        container,
        boardContainer,
        checkingToken,
        data,
        setData,
        status,
        setStatus,
        surveyId,
        setSurveyId,
        sortBy,
        setSortBy,
        search,
        setSearch,
        showAssets,
        setShowAssets,
        isModalOpen,
        setIsModalOpen,
        isFigmaModalOpen,
        setIsFigmaModalOpen,
        isWebsiteModalOpen,
        setIsWebsiteModalOpen,
        isUploadingModalOpen,
        setIsUploadingModalOpen,
        uploadAsset,
        handleOpenFigma,
        reAuthFigma,
        onUploadChange,
        handleSearchAndSort,
        getFigmaData,
        hasManagementFeaturesExperiment,
        showShareModal,
        setShowShareModal,
        onAddToGroup,
        setIsGroupNameModalOpen,
        setGroupName,
        groupName,
        isGroupNameModalOpen,
        onSharingClick,
        isNoLoginGuestExperienceEnabled,
        viewGuestKanban,
        isGuest
      }}
    >
      {children}
    </DesignAssetsStateContext.Provider>
  );
};

type DesignAssetsState = {
  project: AssetProject;
  currentUserId: number;
  figmaCallbackURI: string;
  figmaClientId: string;
  figmaUserToken: string | null;
  bugherdUrl: string;
  container: HTMLDivElement;
  boardContainer: HTMLDivElement;
  checkingToken: boolean;
  data: DesignAsset[];
  setData: (data: DesignAsset[]) => void;
  status: Status;
  setStatus: (status: Status) => void;
  surveyId: string;
  setSurveyId: (surveyId: string) => void;
  sortBy: SortBy | null;
  setSortBy: (sortBy: SortBy | null) => void;
  search: string;
  setSearch: (search: string) => void;
  showAssets: boolean;
  setShowAssets: (showAssets: boolean) => void;
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  isFigmaModalOpen: boolean;
  setIsFigmaModalOpen: (isFigmaOpen: boolean) => void;
  isWebsiteModalOpen: boolean;
  setIsWebsiteModalOpen: (isWebsiteModalOpen: boolean) => void;
  isUploadingModalOpen: boolean;
  setIsUploadingModalOpen: (isUploadingModalOpen: boolean) => void;
  uploadAsset: (params: { onComplete: () => void }) => void;
  handleOpenFigma: () => Promise<void>;
  onUploadChange: (event: UploadChangeParam<any>) => void;
  handleSearchAndSort: () => DesignAsset[];
  getFigmaData: (extraParams: string) => Promise<Response>;
  hasManagementFeaturesExperiment: boolean;
  showShareModal: number | string | false;
  setShowShareModal: (showShareModal: boolean) => void;
  onAddToGroup: (groupName: string) => Promise<void>;
  isGroupNameModalOpen: number | false;
  setIsGroupNameModalOpen: (isGroupNameModalOpen: number | false) => void;
  groupName: string;
  setGroupName: (groupName: string) => void;
  viewGuestKanban: boolean;
  onSharingClick: ({
    assetId,
    siteId,
    assetGroupName
  }: {
    assetId?: string | number;
    siteId?: string | number;
    assetGroupName?: string;
  }) => void;
  isNoLoginGuestExperienceEnabled: boolean;
  isGuest: boolean;
};

const useDesignAssetsState = (): DesignAssetsState => {
  const context = useContext(DesignAssetsStateContext);

  if (context === undefined) {
    throw new Error(
      'useDesignAssetsState must be used within a DesignAssetsProvider'
    );
  }

  // @ts-ignore
  return context;
};

export { DesignAssetsProvider, useDesignAssetsState, DesignAssetsState };
