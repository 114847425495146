export const en = {
  figmaUploadError:
    'Something went wrong. Please check if you have access to this Figma file',
  figmaURLError: "Something went wrong. Please check the URL you've pasted.",
  invalidUrl: 'Please enter a valid URL',
  feedbackCount: (taskCount?: number) =>
    taskCount ? ` (${taskCount} more in feedback)` : '',
  openCount: (taskCount?: number) => `${taskCount} open `,
  tasks: (taskCount?: number) => `task${taskCount === 1 ? '' : 's'}`,
  uploadMessage: (hasExperiment: boolean) =>
    hasExperiment
      ? 'Add your first Design Asset.'
      : 'Click here to upload your design asset (jpg, png, svg, pdf)',
  linkFigma: 'Link a figma file',
  figmaFormLabel: 'Enter a Figma file or prototype URL:',
  imageCaption: 'Where to find your Figma file URL ☝️',
  wentWrong: 'Something went wrong, please try again.',
  copy: 'Copy',
  shareUrlMessage:
    'Copy the link below and share it with anyone in this project.',
  inviteMessage: 'Need to invite someone into this project?',
  shareHeader: 'Share your asset',
  archived: 'ARCHIVED',
  whatAreYouLooking: 'What do you want to get feedback on?',
  addWebsite: 'Add website',
  websiteUrl: 'Website URL:',
  setupOnWebsite: 'Setup BugHerd on this website',
  bugherdSidebarAppear:
    'The BugHerd sidebar will only appear if you have a browser extension or add the javascript code to your website.',
  useExtension: 'Use the Browser Extension',
  installJavascript: 'Install Javascript code',
  save: 'Save',
  updateError: 'There was an error updating your website. Please try again.',
  dontForget: (showJavascriptOption: boolean) =>
    `Don't forget to get the browser extension${
      showJavascriptOption ? 'or install the Javascript code.' : ''
    }`,
  why: ' Why?',
  javascriptCode: 'Javascript code',
  useBrowserExtension: `When your Members and Guests join this project, they will be `,
  useBrowserExtension2: `prompted to install the BugHerd Browser Extension`,
  useBrowserExtension3: ` before they can submit feedback.`,
  getTheExtensionFor: 'Get the extension for yourself and try it now!',
  getExtension: 'Get extension',
  useJavascriptCode:
    'In this case, you need to add this Javascript code to your website. ',
  addThisCode: `Add this project's Javascript code to the`,
  head: '<head>',
  addThisCode2: `of your website and click 'Verify': `,
  verify: 'Verify',
  verifyAgainButton: 'Verify again',
  errorMessage: 'Error verifying your site for the script:',
  successMessage:
    'Verified successfully. BugHerd is installed on your website! 🎉',
  inProgressMessage: 'Checking your site for the script',
  editWebsiteUrl: 'Edit website URL',
  changeUrl: 'Change URL',
  howToSetupBugHerd: 'How to setup BugHerd on your website',
  chooseHowToSetup: 'Choose how you’d like to set up BugHerd:',
  getTheExtension: 'Get the extension',
  simple: 'Simple',
  javascriptInstall: 'Javascript install',
  wordpressInstall: 'Wordpress install',
  installWordPress: 'Install WordPress plugin',
  extensionInfo: `Simply add the extension to your browser and you're ready to use BugHerd on your website.`,
  wordPressInfo:
    "Want to manage feedback and bugs on a WordPress website? Use BugHerd's WordPress" +
    ' plugin for the simplest method of installation.',
  installWordPressTitle:
    'Click the button below to download the plugin from WordPress.org or search for ' +
    '"BugHerd" in your WordPress add-on screen.',
  installWordPressButton: 'Install WordPress plugin',
  projectKeyWordPressTitle:
    'Once installed, enter the "Project Key" below on the plugin settings screen in WordPress.',
  openWebsite: 'Open website',
  beforeFeedbackTitle: `To give feedback with BugHerd you'll need a browser extension`,
  beforeFeedbackDescription:
    'Click the button below to add it to your browser. 👇',
  welcomeHeader: 'Welcome to your project.',
  welcomeMessage:
    'Here you will find all the websites and files shared with you in this project. ',
  sharedWithYou:
    'You can use BugHerd to give feedback on any of these items. To get started, simply click on the item you wish to give feedback on.',
  useBugHerd:
    'You can also find any existing feedback in the {feedbackLink} or {taskBoardLink} sections of this project.',
  linksHeader: 'Quicklinks',
  owner: 'Owner',
  contact: 'Contact',
  toUseBugherd: 'To use BugHerd on this website...',
  youllNeedExtensionOrCode:
    'You’ll need to either add the browser extension to your browser, or install the Javascript code on your website.',
  chooseHow: 'Choose how you’d like to set up BugHerd 👇',
  needHelp: 'Need help?',
  contactSupport: 'Contact support'
};

export type Translations = typeof en;
