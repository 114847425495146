import React, { useEffect, useRef } from 'react';
import cx from 'classnames';
import { format, parseISO } from 'date-fns';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Image, FileText, Video } from 'lucide-react';
import Avatar from '../../../../clients/shared/Avatar';
import HighlightedMentions from 'jsComponents/HighlightedMentions';

import {
  Calendar,
  Tag,
  MousePointer as Arrow,
  Archive,
  Figma
} from 'lucide-react';

import styles from './index.module.css';
import { Tooltip, Checkbox } from 'antd';
import {
  SELECT_TASK_GROUP,
  SELECT_TASK_RANGE,
  wasMultiSelectKeyUsed,
  wasToggleInSelectionGroupKeyUsed
} from '../multiSelect/Util';
import { CommentIndicator } from '../../../../../clients/shared/CommentIndicator/CommentIndicator';

const keyCodes = {
  enter: 13
};

type Props = {
  setActiveTask: (id: number) => void;
  action: string;
  isActive: boolean;
  moveTaskToFeedback: (task: any) => void;
  moveTaskToArchive: (task: any) => void;
  getAssignees: (id: number) => any;
  id: number;
  due_at: string;
  local_task_id: number;
  asset_id: number | {};
  tag_ids: number[];
  tags: Object;
  description: string;
  priority_id: number;
  dragHandleProps: any;
  multiSelect: (id: string, type: string) => void;
  unSelectAll: () => void;
  selectedTaskList: Array<string>;
  asset_type: string | Object;
  comments: { mention_ids: number[]; read_by_user_ids: number[] }[];
  hasScreenRecording: boolean;
  viewGuestKanban: boolean;
  canDrag: boolean;
};

const getPriorityClass = (priorityId: number) => {
  return (
    [
      styles.notSet,
      styles.critical,
      styles.important,
      styles.normal,
      styles.minor
    ][priorityId] || styles.notSet
  );
};

const KanbanCard: React.FC<Props> = props => {
  const {
    local_task_id,
    asset_id,
    asset_type,
    id,
    due_at,
    tags,
    action,
    selectedTaskList,
    tag_ids,
    isActive,
    priority_id,
    comments,
    hasScreenRecording,
    viewGuestKanban,
    canDrag
  } = props;

  const cardRef = useRef();

  const isMultiSelectState =
    id &&
    selectedTaskList.length > 1 &&
    selectedTaskList.includes(id.toString());

  const scrollToCard = ({ animate }: { animate: boolean }) => {
    setTimeout(() => {
      if (cardRef.current) {
        // Using parentElement is no good, but because of the styling (margin/padding)
        // and the autogenerated elements from drag and drop, it's a good enough solution
        scrollIntoView(cardRef.current.parentElement, {
          behavior: animate ? 'smooth' : 'auto'
        });
      }
    }, 100);
  };

  useEffect(() => {
    if (isActive && !isMultiSelectState) scrollToCard({ animate: false });
  }, []);

  useEffect(() => {
    if (isActive && !isMultiSelectState) scrollToCard({ animate: true });
  }, [isActive]);

  const moveToFeedback = (event, task) => {
    event.stopPropagation();
    props.moveTaskToFeedback(task);
  };

  const moveToArchive = (event, task) => {
    event.stopPropagation();
    props.moveTaskToArchive(task);
  };

  const getAssignees = () => {
    return props
      .getAssignees(id)
      .slice(0, 3)
      .map(user => {
        return {
          name: user.name,
          email: user.email,
          avatar: user.avatar,
          role: user.role !== 'collaborator' ? 'member' : user.role,
          firstName: user.first_name,
          surname: user.surname
        };
      });
  };

  const performAction = (event, checkboxPresent) => {
    const { setActiveTask, multiSelect, unSelectAll, id } = props;

    if (wasMultiSelectKeyUsed(event)) {
      multiSelect(id.toString(), SELECT_TASK_RANGE);
    } else if (wasToggleInSelectionGroupKeyUsed(event) || checkboxPresent) {
      multiSelect(id.toString(), SELECT_TASK_GROUP);
    } else {
      setActiveTask(id);
      unSelectAll();
    }
  };

  const onCardClick = event => {
    event.preventDefault();
    //clicked on checkbox
    if (typeof event.target.checked === 'boolean') {
      performAction(event, 'checkboxPresent');
      //clicked on card but not on checkbox
    } else {
      performAction(event, null);
    }
  };

  const onKeyDown = event => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.keyCode !== keyCodes.enter) {
      return;
    }
    event.preventDefault();
    performAction(event, undefined);
  };

  const assignees = getAssignees();

  const tagsString = (tag_ids || [])
    .map(tagId => (tags && tags[tagId] ? tags[tagId].get('name') : ''))
    .filter(name => name && name !== '')
    .join(', ');

  const unreadCommentsEnabled = true;
  const currentUserId = window.bugherd?.application.attributes.currentUserId;

  return (
    <div
      className={styles.cardOuter}
      ref={cardRef}
      {...props.dragHandleProps}
      data-intercom-target="Task"
      data-task-id={id}
      data-performance-event="KanbanCard"
      onClick={e => {
        e.currentTarget.focus();
        onCardClick(e);
      }}
    >
      <div
        className={cx({
          [styles.card]: true,
          [styles.activeCard]: isActive,
          [styles.activeCardMultiSelect]: isMultiSelectState,
          [styles.activeCardMultiSelectBG]: isMultiSelectState
        })}
        onKeyDown={event => onKeyDown(event)}
        data-performance-event="KanbanCard"
      >
        <div
          className={cx([
            styles.priorityIndicator,
            getPriorityClass(priority_id)
          ])}
        />
        <div
          className={styles.cardInnerContent}
          data-performance-event="KanbanCard"
        >
          <div
            className={cx(
              {
                [styles.hasAction]: !!action
              },
              styles.metadataContainer
            )}
          >
            <div className={styles.idCheckboxWrapper}>
              <span className={styles.idInfo}>
                {local_task_id && `#${local_task_id}`}

                {asset_id && (
                  <span className={styles.assetIcon}>
                    {asset_type === 'figma' && (
                      <>
                        <Figma className={styles.imageIcon} />
                        FIGMA
                      </>
                    )}
                    {asset_type === 'pdf' && (
                      <>
                        <FileText className={styles.fileIcon} />
                        PDF
                      </>
                    )}
                    {asset_type !== 'figma' && asset_type !== 'pdf' && (
                      <>
                        <Image className={styles.imageIcon} />
                        IMAGE
                      </>
                    )}
                  </span>
                )}
              </span>

              {action && canDrag && (
                <Tooltip
                  title={`Move task to ${action.slice(2)}`}
                  placement={action.includes('Feedback') ? 'right' : 'left'}
                  // @ts-expect-error
                  getPopupContainer={() =>
                    document.getElementById('kanbanBoard')
                  }
                >
                  {action.includes('Feedback') ? (
                    <Arrow
                      onClick={event => moveToFeedback(event, { id })}
                      className={styles.cardActionIcon}
                    />
                  ) : (
                    <Archive
                      onClick={event => moveToArchive(event, { id })}
                      className={styles.cardActionIcon}
                    />
                  )}
                </Tooltip>
              )}

              <Checkbox
                data-performance-event="BulkActionsCheckbox"
                className={styles.checkboxMultiselect}
                checked={isActive}
              />
            </div>
          </div>
          <p className={styles.description} data-performance-event="KanbanCard">
            <HighlightedMentions
              {...{
                description: props.description,
                className: styles.mentionText
              }}
            />
          </p>
          {unreadCommentsEnabled && (
            <CommentIndicator
              comments={comments}
              currentUserId={currentUserId}
            />
          )}
        </div>

        <div
          className={cx({
            [styles.activeCardMultiSelectBG]: isMultiSelectState,
            [styles.taskInfoFooter]: true
          })}
        >
          {due_at && (
            <div className={styles.dueBox}>
              <Calendar className={styles.dueIcon} />
              {format(parseISO(due_at), 'd MMM yyyy')}
            </div>
          )}
          {tag_ids.length > 0 && (
            <div className={styles.tagBox} title={`tags: ${tagsString}`}>
              <Tag className={styles.tagIcon} />
              <span className={styles.tags}>{tagsString}</span>
            </div>
          )}
          {hasScreenRecording && <Video className={styles.videoIcon} />}
          {!viewGuestKanban && (
            <div className={styles.assigneesContainer}>
              {assignees.map(assignee => {
                return (
                  <div
                    className={styles.avatarContainer}
                    key={assignee.email}
                    title={assignee.email}
                  >
                    <Avatar member={assignee} size="small" />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default KanbanCard;
