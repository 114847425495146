import React, { useState, useEffect, ReactText, useRef } from 'react';
import { Input, Select, Form, InputRef } from 'antd';
import cx from 'classnames';

import { getLangKey } from 'models/Language';
import * as translations from './strings';
import styles from './style.module.css';
import {
  getUrlProtocol,
  stripUrlProtocol,
  decodeURLAndStripProtocol
} from 'utils/projectSettingsOperations';
import ValidationStateIcon from './ValidationStateIcon';
import HelpArticleButton from 'appJS/utils/HelpArticleButton';
import ContainerContext from 'views/project_settings/containerContext';
import { validateUrl, ValidateStatus } from 'utils/validateUrls';

interface Props {
  onChange: (value: string) => void;
  currentUrl: string;
  fieldName: ReactText[];
  isUrlRequired?: boolean;
  showUrlPlaceHolder?: boolean;
  container?: HTMLElement;
  autoFocus?: boolean;
  validateFirst?: boolean;
  shouldValidateDNS?: boolean;
  placeHolder?: string;
  checkUrlOnEnter?: boolean;
  showHelpWarning?: boolean;
}

const ProjectUrlInput = ({
  onChange,
  currentUrl,
  fieldName,
  isUrlRequired,
  showUrlPlaceHolder,
  container,
  autoFocus,
  validateFirst,
  shouldValidateDNS = true,
  placeHolder,
  checkUrlOnEnter,
  showHelpWarning
}: Props) => {
  const [urlProtocol, setUrlProtocol] = useState<string>(
    getUrlProtocol(currentUrl)
  );
  const [projectUrl, setProjectUrl] = useState<string>(
    decodeURLAndStripProtocol(currentUrl)
  );

  const [validateStatus, setValidateStatus] = useState<ValidateStatus>(
    ValidateStatus.Default
  );
  const [validationMessage, setValidationMessage] = useState<string>();

  const strings = translations[getLangKey()];

  const urlInputRef = useRef<InputRef>(null);

  const validateDNS = async (_rule: any, value: string): Promise<boolean> => {
    if (validateStatus !== ValidateStatus.Default) {
      return validateStatus === ValidateStatus.Error
        ? Promise.reject(new Error(validationMessage))
        : Promise.resolve(true);
    }

    setValidationMessage(undefined);
    if (!value) {
      if (!!isUrlRequired) {
        setValidateStatus(ValidateStatus.Error);
        setValidationMessage(strings.urlRequiredMessage);
        return Promise.reject(new Error(strings.urlRequiredMessage));
      }
      return Promise.resolve(true);
    }
    setValidateStatus(ValidateStatus.Validating);
    const { status, message } = await validateUrl(urlProtocol + value);
    setValidateStatus(status);
    if (status === ValidateStatus.Error) {
      setValidationMessage(message);
      return Promise.reject(false);
    }
    if (status === ValidateStatus.Warning) setValidationMessage(message);
    return Promise.resolve(true);
  };

  const onUrlChanged = () => {
    const newUrl = `${urlProtocol}${projectUrl}`;
    if (newUrl !== currentUrl) {
      setValidateStatus(ValidateStatus.Default);
      onChange(newUrl);
    }
  };

  useEffect(() => {
    if (!currentUrl) {
      setValidateStatus(ValidateStatus.Default);
      setValidationMessage(undefined);
    }
    setUrlProtocol(getUrlProtocol(currentUrl));
    setProjectUrl(decodeURLAndStripProtocol(currentUrl));
  }, [currentUrl]);

  const getContainer = container || React.useContext(ContainerContext);

  const selectBefore = (
    <Select
      defaultValue={urlProtocol}
      className={styles.selectBefore}
      style={{ width: 100 }}
      onChange={value => {
        setUrlProtocol(value);
        setValidateStatus(ValidateStatus.Default);
        onChange(`${value}${projectUrl}`);
      }}
      bordered={false}
      getPopupContainer={() => getContainer as HTMLElement}
    >
      <Select.Option value="http://">http://</Select.Option>
      <Select.Option value="https://">https://</Select.Option>
    </Select>
  );

  return (
    <Form.Item
      name={fieldName}
      validateStatus={validateStatus}
      initialValue={projectUrl}
      validateTrigger={'onBlur'}
      className={styles.urlInputItem}
      help={
        (validateStatus === ValidateStatus.Error && validationMessage) ||
        (showHelpWarning && validateStatus === ValidateStatus.Warning && (
          <>
            <span>We couldn't find this URL.</span>{' '}
            <HelpArticleButton
              articleId={'84878'}
              style={{ textDecoration: 'underline' }}
            >
              <span>Why?</span>
            </HelpArticleButton>
          </>
        ))
      }
      rules={[
        {
          required: !!isUrlRequired
        },
        ...(shouldValidateDNS ? [{ validator: validateDNS }] : [])
      ]}
      getValueFromEvent={event => {
        const newProjectUrl = stripUrlProtocol(event.target.value);
        setProjectUrl(newProjectUrl);
        return newProjectUrl;
      }}
    >
      <Input
        ref={urlInputRef}
        placeholder={
          placeHolder || (showUrlPlaceHolder ? 'www.amazing.com' : undefined)
        }
        autoFocus={autoFocus}
        className={cx(styles.inputBorder, styles.inputBorderSucccess, {
          [styles.inputBorderError]: validateStatus === ValidateStatus.Error,
          [styles.inputBorderWarn]: validateStatus === ValidateStatus.Warning
        })}
        addonBefore={selectBefore}
        addonAfter={
          <ValidationStateIcon
            validateStatus={validateStatus}
            validationMessage={validationMessage}
            container={() => getContainer as HTMLElement}
          />
        }
        onPressEnter={onUrlChanged}
        onBlur={onUrlChanged}
        onKeyDown={checkUrlOnEnter ? onUrlChanged : undefined}
      />
    </Form.Item>
  );
};

export default ProjectUrlInput;
