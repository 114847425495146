import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Loader from 'components/loader';
import HighlightedMentions from 'components/HighlightedMentions';

import { Checkbox } from 'antd';

import styles from '../index.module.css';
import { CommentIndicator } from '../../../../../../clients/shared/CommentIndicator/CommentIndicator';
import AvatarList from '../../../../../clients/shared/AvatarList';

const TaskListItem = props => {
  const {
    task: {
      id,
      priority_id,
      local_task_id,
      description,
      bulk_request_id,
      comments
    },
    index,
    taskEditStatus,
    groupTotal,
    handleTaskClick,
    moveToTaskBoard,
    isAdminView,
    activeTaskId,
    taskActive,
    isArchiveViewActive,
    isSelected,
    assignedUsers
  } = props;

  const unreadCommentsEnabled = true;

  const currentUserId = window.bugherd?.application.attributes.currentUserId;

  return (
    <div
      key={id}
      className={cx(styles.cardContainer, {
        [styles.isListBar]: !isAdminView,
        [styles.lastInGroup]: groupTotal - 1 === index,
        [styles.firstInGroup]: index === 0,
        [styles.isSelected]: isSelected && !isArchiveViewActive,
        [styles.showAllCheckboxes]: taskActive
      })}
      onClick={event => handleTaskClick(event, id.toString())}
    >
      <div
        className={cx(styles.cardInner, {
          [styles.activeTask]: activeTaskId === id
        })}
      >
        <span className={cx(styles.cardID, styles[`priority${priority_id}`])}>
          {local_task_id}
        </span>
        <div className={styles.cardContent}>
          <div
            className={cx({
              [styles.description]: true,
              [styles.descriptionAndProgress]: isAdminView
            })}
          >
            {!isArchiveViewActive && (
              <Checkbox
                className={styles.checkboxWrapper}
                checked={isSelected}
                key={local_task_id}
              ></Checkbox>
            )}

            {unreadCommentsEnabled && (
              <CommentIndicator
                comments={comments}
                currentUserId={currentUserId}
              />
            )}
            {description !== null ? (
              <HighlightedMentions
                {...{
                  description,
                  className: styles.taskDescription
                }}
              />
            ) : (
              description
            )}
            {bulk_request_id && (
              <div className={styles.bulkRequestProgress}>
                <Loader size="small" />
                <span className={styles.archiving}>Archiving...</span>
              </div>
            )}
          </div>
          <div className={styles.cardEnd}>
            {moveToTaskBoard && taskEditStatus && (
              <span
                className={styles.toTaskBoard}
                onClick={event => {
                  event.stopPropagation();
                  moveToTaskBoard(id);
                }}
              >
                move to task board
              </span>
            )}
            {!!assignedUsers.length && (
              <AvatarList assignedUsers={assignedUsers} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

TaskListItem.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    priority_id: PropTypes.number,
    local_task_id: PropTypes.number,
    description: PropTypes.string,
    bulk_request_id: PropTypes.number
  }),
  index: PropTypes.number,
  taskEditStatus: PropTypes.bool,
  groupTotal: PropTypes.number,
  handleTaskClick: PropTypes.func,
  moveToTaskBoard: PropTypes.func,
  activeTaskId: PropTypes.number,
  isAdminView: PropTypes.bool
};

export default TaskListItem;
