import React, { useState, useEffect } from 'react';
import styles from './microsoftTeamsSettings.module.css';
import { get, post } from '../../utils/fetch';
import { Form, Row, Col, Button, Select, Tooltip, Checkbox, Input } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { message } from 'antd';

interface Props {
  projectId: string;
  organizationId: string;
  integration:
    | {
        enabled: boolean;
        id: string;
        settings: {
          site_name: string;
          task_list: Object;
          project: Object;
          new_tasks: boolean;
          status_updates: boolean;
        };
      }
    | undefined;
}

const TeamworkSettings: React.FC<Props> = (props: Props) => {
  const [form] = Form.useForm();

  const { integration, organizationId, projectId } = props;
  const [projectIntegrationId, setProjectIntegrationId] = useState('');
  const [selectedSiteName, setSelectedSiteName] = useState(
    integration?.settings?.site_name
  );
  const [selectedProject, setSelectedProject] = useState(
    integration?.settings?.project
  );
  const [selectedTaskList, setSelectedTaskList] = useState(
    integration?.settings?.task_list
  );
  const [autoPost, setAutoPost] = useState(
    integration?.settings?.new_tasks.toString() === 'true'
  );
  const [syncStatus, setSyncStatus] = useState(
    integration?.settings?.status_updates.toString() === 'true'
  );
  const [remoteProjectOptions, setRemoteProjectOptions] = useState([]);
  const [remoteTaskListOptions, setTaskListOptions] = useState([]);

  function debounce(func, wait) {
    let timeout;
    return function(...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  const handleProjectSelect = (value: string, label: Object) => {
    setSelectedProject(label);
    getTaskList(selectedSiteName, value);
  };

  const handleTaskListSelect = (value: string, label: Object) => {
    setSelectedTaskList(label);
  };

  const handleSiteNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const siteName = e.target.value;
    setSelectedSiteName(siteName);

    if (siteName) {
      getTeamOptions(siteName);
    }
  };

  const handleSiteNameChangeDebounced = debounce(handleSiteNameChange, 2000);

  const getConnectedState = async () => {
    if (!integration || !integration?.enabled) return;
    try {
      let results = await get(
        `/organizations/${organizationId}/integrations/integration_connection?project_id=${projectId}&integration_code=Teamwork`
      );
      setProjectIntegrationId(results.project_integration_id);
      setSelectedProject(results.settings.project);
      setSelectedSiteName(results.settings.site_name);
      setAutoPost(results.settings.new_tasks.toString() === 'true');
      setSyncStatus(results.settings.status_updates.toString() === 'true');
    } catch (error) {
      message.error('Something went wrong, please try again.');
    }
  };

  const getTeamOptions = async (siteName?: string) => {
    if (!integration || !integration?.enabled) return;
    try {
      let results = await get(
        `/organizations/${organizationId}/integrations/remote_projects?id=${
          integration.id
        }&site_name=${siteName || selectedSiteName}`
      );
      setRemoteProjectOptions(results);
    } catch (error) {
      message.error('Something went wrong, please try again.');
    }
  };

  const getTaskList = async projectId => {
    if (!integration || !integration?.enabled) return;
    try {
      let results = await get(
        `/organizations/${organizationId}/integrations/teamwork_task_lists?id=${
          integration.id
        }&site_name=${selectedSiteName}&project_id=${projectId ||
          selectedProject.value}`
      );
      setTaskListOptions(results);
    } catch (error) {
      message.error('Something went wrong, please try again.');
    }
  };

  useEffect(() => {
    getConnectedState();

    if (selectedSiteName) {
      getTeamOptions(selectedSiteName);
    }

    if (selectedProject) {
      getTaskList();
    }
  }, []);

  const saveSettings = async () => {
    try {
      await post(
        `/organizations/${organizationId}/integrations/${integration.id}/project_integrations/save_settings?integration_code=Teamwork`,
        {
          project_id: projectId,
          project_integration_id: projectIntegrationId,
          settings: {
            site_name: selectedSiteName,
            project: selectedProject,
            task_list: selectedTaskList,
            new_tasks: autoPost,
            status_updates: syncStatus
          }
        }
      );
      message.success('Settings saved', 5);
    } catch (error) {
      console.error('Error saving settings', error);
      message.error('Something went wrong, please try again.');
    }
  };

  const renderConnect = () => {
    return (
      <div className={styles.teamsExplanation}>
        <p>
          Connect BugHerd with your Teamwork account to sync your tasks and and
          and comments. For example:
        </p>
        <ul>
          <li>Post tasks to your Teamwork kanban board</li>
          <li>Sync new status updates to your connected tasks</li>
          <li>Sync comments with teamwork</li>
        </ul>
        <a
          className="btn ant-btn-primary"
          rel="nofollow"
          data-method="post"
          href={`/auth/teamwork?project=${projectId}&organization=${organizationId}&type=Teamwork`}
        >
          <span className={styles.buttonText}>Connect</span>
        </a>
      </div>
    );
  };

  const renderIntegrationSettings = () => {
    return (
      <div>
        <p className={styles.teamsChannelHeader}>
          Teamwork
          <span className={styles.infoContainer}>
            <Tooltip title="Choose the team and channel you want to sync with BugHerd">
              <InfoCircleOutlined style={{ color: '#3F71E0' }} />
            </Tooltip>
          </span>
        </p>
        <Form form={form} onFinish={saveSettings}>
          <Row className={styles.teamsEditRow}>
            <Col span={10}>
              <Form.Item
                name="sitename"
                rules={[{ required: true }]}
                initialValue={selectedSiteName}
              >
                <Input
                  value={selectedSiteName}
                  onChange={handleSiteNameChangeDebounced}
                  placeholder="Site Name"
                />
              </Form.Item>
            </Col>
            <Col span={10}>.teamwork.com</Col>
          </Row>
          <Row className={styles.teamsEditRow}>
            <Col span={10}>
              <Form.Item
                name="project"
                rules={[{ required: true }]}
                initialValue={selectedProject}
              >
                <Select
                  options={remoteProjectOptions}
                  onSelect={handleProjectSelect}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.teamsEditRow}>
            <Col span={10}>
              <Form.Item
                name="tasklist"
                rules={[{ required: true }]}
                initialValue={selectedTaskList}
              >
                <Select
                  options={remoteTaskListOptions}
                  onSelect={handleTaskListSelect}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.teamsEditRow}>
            <Form.Item>
              <Checkbox
                checked={autoPost}
                onChange={e => setAutoPost(e.target.checked)}
              >
                Automatically post new tasks to Teamwork
              </Checkbox>
            </Form.Item>
          </Row>
          <Row className={styles.teamsEditRow}>
            <Form.Item>
              <Checkbox
                checked={syncStatus}
                onChange={e => setSyncStatus(e.target.checked)}
              >
                Sync status updates
              </Checkbox>
            </Form.Item>
          </Row>
          <Row className={styles.teamsEditRow}>
            <Col span={6}>
              <Form.Item>
                <Button
                  type="primary"
                  shape="round"
                  size="default"
                  htmlType="submit"
                  className={styles.save}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {integration?.enabled ? renderIntegrationSettings() : renderConnect()}
    </div>
  );
};

export default (props: Props) => <TeamworkSettings {...props} />;
