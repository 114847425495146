import React from 'react';
import { Tag as TagIcon, CirclePlus } from 'lucide-react';
import Avatar from 'appClients/shared/Avatar';
import styles from './styles.module.css';
import { priorities, Priority } from '../../right_panel/severity';
import { create } from 'cypress/types/lodash';

export type Trigger = '@' | '#' | '$' | '!';

enum ShortcutName {
  Assignees = 'assignees',
  Status = 'status',
  Tags = 'tags',
  Severity = 'severity'
}

export type Shortcut = {
  name: ShortcutName;
  data: any[];
  trigger: Trigger;
  afterSelect: (id: number) => void;
  optionProps: (
    item: any
  ) => {
    icon?: React.ReactNode;
    value: string;
    active?: boolean;
    className?: string;
  };
  itemKey?: 'display';
  permission: boolean;
};

export const getShortcuts = ({
  assignees,
  canEditAssignees,
  assignableUsers,
  onAssigneesChange,
  status,
  canEditStatus,
  onStatusChange,
  columns,
  tags,
  canEditTags,
  onTagsChange,
  availableTags,
  severity,
  canEditSeverity,
  onSeverityChange
}): Shortcut[] => [
  {
    name: ShortcutName.Assignees,
    data: assignableUsers.filter(user => !assignees.includes(user.id)),
    trigger: '@',
    afterSelect: (id: number) => {
      const assigneesClone = assignees.slice();
      assigneesClone.push(id);
      onAssigneesChange(assigneesClone);
    },
    optionProps: (item: any) => ({
      value: item.name,
      icon: <Avatar member={item} size="small" />
    }),
    permission: canEditAssignees
  },
  {
    name: ShortcutName.Status,
    data: columns,
    trigger: '$',
    afterSelect: (id: number) => onStatusChange(id),
    optionProps: (item: any) => ({
      value: item.name,
      active: status === item.id
    }),
    permission: canEditStatus
  },
  {
    name: ShortcutName.Tags,
    data: availableTags.filter(tag => !tags.find(({ id }) => id === tag.id)),
    trigger: '#',
    afterSelect: (id: number) => {
      const tag = availableTags.find(({ id: tagId }) => tagId === id);
      const tagsClone = tags.slice();
      if (tag) {
        tagsClone.push(tag);
        onTagsChange(tagsClone);
      }
    },
    optionProps: (item: any) => ({
      value: item.display,
      icon:
        item.id < 1 ? (
          <CirclePlus className={styles.newTagIcon} />
        ) : (
          <TagIcon className={styles.tagIcon} />
        )
    }),
    itemKey: 'display',
    permission: canEditTags
  },
  {
    name: ShortcutName.Severity,
    data: priorities,
    trigger: '!',
    afterSelect: (id: number) => onSeverityChange(id),
    optionProps: (item: Priority) => ({
      value: item.name,
      className: `severity-${item.id}`,
      active: severity === item.id
    }),
    permission: canEditSeverity
  }
];
