import React, { FC, useState, useEffect, useImperativeHandle } from 'react';
import { checkForExtension } from 'utils/checkForBugherdExtension';
import UseBugherdModal from './Sites/UseBugherdModal';
import styles from './index.module.css';
import { Button } from 'antd';
import track from 'jsUtilities/analytics';
import { fetchWithTimeout } from './util';
import { post } from 'appJS/utils/fetch';
import { CircleAlert, ExternalLink } from 'lucide-react';
import { getProjectSite } from './Sites/ProjectSites';

export const checkIsCompatibleSite = async (
  proxyUrl: string,
  projectSiteUrl: string
) => {
  const compatibleEndpoint = `${proxyUrl}/compatibility?url=${projectSiteUrl}`;
  try {
    const response = await fetchWithTimeout(compatibleEndpoint);
    const { compatible } = await response.json();
    return !!compatible;
  } catch (error) {
    console.error(error);
    return false;
  }
};

type Props = {
  proxyUrl: string;
  projectSiteId: number | string;
  projectSiteUrl: string;
  getTriggerElement: (loading: boolean) => React.ReactNode;
  project: any;
  apiKey?: string;
  onSharingClick?: () => void;
  showProxyPreview?: boolean;
  isGuest?: boolean;
  isNoLoginGuestExperienceEnabled?: boolean;
  childRef?: any;
};

const ProxyCompatibilityChecker: FC<Props> = ({
  proxyUrl,
  projectSiteId,
  projectSiteUrl,
  getTriggerElement,
  project,
  apiKey,
  showProxyPreview,
  isGuest,
  isNoLoginGuestExperienceEnabled,
  childRef
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [site, setSite] = useState<{ token: string | null; id: number } | null>(
    null
  );
  const [hasExtensionInstalled, setExtensionInstalled] = useState<boolean>(
    false
  );
  const [showInstallModal, setShowInstallModal] = useState<boolean>(false);
  const [isProxyCompatible, setIsProxyCompatible] = useState<boolean | null>(
    null
  );
  const hasJsScript = site?.events?.includes('site_js_verified') || false;
  const isReadyToOpen =
    isGuest || isProxyCompatible || hasExtensionInstalled || hasJsScript;

  const { websites, id: projectId, showAddWebsiteOnboarding } = project || {};

  const container = document.body;

  const checkCompatibility = async () => {
    if (!projectSiteUrl) return;
    const isCompatible = await checkIsCompatibleSite(proxyUrl, projectSiteUrl);
    setIsProxyCompatible(isCompatible);
    return isCompatible;
  };

  const revalidateJsScript = async () => {
    try {
      if (!hasJsScript && site) {
        await post(
          `/projects/${projectId}/project_sites/${projectSiteId}/validate`,
          {}
        );
      }
    } catch (error) {
      console.error('Error checking readiness:', error);
    }
  };

  const trackSiteOpening = () => {
    track('Opened Project Site', {
      viaProxy: false,
      viaExtension: hasExtensionInstalled,
      viaScriptInstalled: hasJsScript
    });
  };

  const navigateToProxy = async () => {
    const endpoint = `/projects/${projectId}/sites/${projectSiteId}?apikey=${project?.apiKey ||
      apiKey}`;
    const response = await fetch(endpoint);
    if (!response.ok) {
      throw new Error(`An error has occurred: ${response.status}`);
    }

    track('Opened Project Site', { viaProxy: true });
    window.location.href = endpoint;
  };

  const openWebsite = () => {
    trackSiteOpening();
    window.open(projectSiteUrl, '_blank');
  };

  const openInstallModal = () => {
    setShowInstallModal(true);
    track('Shown install modal');
  };

  const openWebsiteOrOpenInstallModal = () => {
    if (hasExtensionInstalled || hasJsScript) openWebsite();
    else openInstallModal();
  };

  const initAndOpenShareURL = async () => {
    try {
      let { token } =
        site ||
        (await post(`/share_tokens`, {
          project_site_id: projectSiteId,
          asset_id: null
        }));
      if (token) window.open(`/share/${token}`, '_self');
    } catch (error) {
      console.error('Error generating share token:', error);
    }
  };

  const handleTriggerClick = async () => {
    if (isNoLoginGuestExperienceEnabled && isGuest) {
      return initAndOpenShareURL();
    }
    if (showProxyPreview) {
      if (hasJsScript) {
        openWebsite();
        return;
      } else if (isProxyCompatible) {
        try {
          return await navigateToProxy();
        } catch (error) {
          console.error(error);
        }
      }
    }
    openWebsiteOrOpenInstallModal();
  };

  useImperativeHandle(childRef, () => ({
    handleTriggerClick
  }));

  useEffect(() => {
    if (isLoading) return;
    if (showAddWebsiteOnboarding && websites.length === 0) {
      handleTriggerClick();
    }
  }, [websites?.length, isLoading]);

  useEffect(() => {
    (async () => {
      if (!projectSiteId) return;
      const response = await getProjectSite(projectId, String(projectSiteId));
      setSite(response?.project_site);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await revalidateJsScript();
        if (showProxyPreview) {
          await checkCompatibility();
        }
        const exists = await checkForExtension();
        if (exists) {
          setExtensionInstalled(exists);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [site]);

  const renderButton = () => {
    let buttonText = 'Open website';
    let icon = <ExternalLink size={16} />;
    let type: 'primary' | 'default' = 'primary';
    let className = styles.guestButton;

    if (isLoading && !isGuest) {
      buttonText = 'Checking website..';
      className = styles.loadingButton;
    } else if (isReadyToOpen) {
      buttonText = 'Open website';
      type = 'primary';
      className = '';
    } else {
      buttonText = 'Install required';
      className = styles.installRequiredButton;
    }

    if (!isReadyToOpen) {
      icon = <CircleAlert size={16} />;
      type = 'default';
    }

    return (
      <Button
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          handleTriggerClick();
        }}
        type={type}
        icon={icon}
        loading={isLoading}
        className={className}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <>
      <div className={styles.getTriggerElement} onClick={handleTriggerClick}>
        {getTriggerElement(isLoading)}
      </div>
      {!getTriggerElement(isLoading) && renderButton()}
      <UseBugherdModal
        container={container}
        isUseBugherdModalOpen={showInstallModal}
        handleCloseUseBugherdModal={() => {
          setShowInstallModal(false);
          window.location.reload();
        }}
        url={projectSiteUrl}
        isGuest={isGuest}
      />
    </>
  );
};

export default ProxyCompatibilityChecker;
